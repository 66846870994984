/* General Styles */
.navbar {
  background: linear-gradient(
    -45deg,
    #000000,
    #000000,
    #000000,
    #000000
  ); /* Dynamic gradient colors */
  background-size: 400% 400%; /* Ensure the gradient spans the entire navbar */
  animation: gradientAnimation 10s ease infinite; /* Add an animation for the gradient */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 8px 16px; /* Add some padding for spacing */
}

/* Gradient Animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Logo Button Styling */
.navbar-logo {
  cursor: pointer;
  margin-right: 16px; /* Space between logo and title */
}

.navbar-logo-button:hover {
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ) !important; /* Subtle hover effect */
}

/* Title Styling */
.navbar-title {
  font-family: "Audiowide", "Bebas Neue", "Oswald", sans-serif; /* Match the font used in headings */
  font-size: 24px; /* Slightly larger than other text */
  font-weight: bold;
  color: white; /* Contrast with the dark background */
  cursor: pointer;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3), 1px 1px 0 rgba(0, 0, 0, 0.3); /* Add depth */
}

/* User Menu Styling */
.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-account-icon {
  color: white; /* Ensure visibility against the gradient background */
  margin-left: 16px; /* Space between the icon and other elements */
  font-size: 28px; /* Slightly larger icon size */
}

.navbar-menu-item {
  color: white; /* Menu item text color */
  text-transform: capitalize; /* Capitalize menu items for readability */
  font-size: 16px;
  padding: 8px 16px; /* Padding for better clickability */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
  border-radius: 4px; /* Rounded corners for modern look */
}

.navbar-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Subtle hover effect */
  color: purple; /* Change color on hover for interactivity */
}

.navbar-menu-list {
  padding: 8px 0; /* Adjust padding for the menu list */
  background: linear-gradient(
    -45deg,
    #000000,
    #000000,
    #000000,
    #161616
  ); /* Match the navbar gradient */
  background-size: 400% 400%; /* Ensure the gradient spans the dropdown */
  animation: gradientAnimation 10s ease infinite; /* Add an animation for the gradient */
  border-radius: 8px; /* Rounded corners for the dropdown */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    padding: 8px; /* Reduce padding for smaller screens */
  }

  .navbar-title {
    font-size: 20px; /* Reduce font size for better fit */
  }

  .navbar-account-icon {
    font-size: 24px; /* Reduce icon size */
  }

  .navbar-menu-item {
    font-size: 14px; /* Reduce font size for compactness */
    padding: 6px 12px; /* Reduce padding for better spacing */
  }
}
