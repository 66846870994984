/* Container for rhyme suggestions */
.rhyme-suggestions-container {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 12px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title for the rhyme suggestions section */
.rhyme-suggestions-container h3 {
    font-size: 1.2rem;
    color: #ff6f00; /* Bright orange */
    margin-bottom: 10px;
    font-weight: 600;
}

/* List of rhymes */
.rhymes-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    margin-top: 10px;
}

/* Individual rhyme item */
.rhyme-item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Hover effect for rhyme items */
.rhyme-item:hover {
    background-color: #f0f8ff;
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Favorite button styling */
.favorite-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
    color: #aaa;
    transition: color 0.3s;
}

/* Highlight favorite button when hovered */
.favorite-btn:hover {
    color: gold;
}

/* Pagination controls */
.pagination-controls {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

/* Pagination buttons */
.pagination-controls button {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #fff;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s, transform 0.2s;
}

/* Active pagination button */
.pagination-controls button.active,
.pagination-controls button:hover {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
    transform: scale(1.05);
}

/* Disabled pagination button */
.pagination-controls button:disabled {
    background-color: #eee;
    cursor: not-allowed;
    opacity: 0.6;
}

/* Favorites section */
.favorites-section {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
}

/* Favorites title */
.favorites-section h4 {
    font-size: 1.1rem;
    color: #ff6f00;
    margin-bottom: 10px;
    font-weight: 600;
}

/* Favorites list */
.favorites-section ul {
    list-style-type: none;
    padding: 0;
}

/* Individual favorite item */
.favorites-section li {
    padding: 8px 0;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Remove button for favorites */
.favorites-section .remove-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #dc3545;
    transition: color 0.3s;
}

/* Highlight remove button when hovered */
.favorites-section .remove-btn:hover {
    color: #c82333;
}

.shuffle-controls {
  margin-top: 10px;
  text-align: center;
}

.shuffle-controls button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s, transform 0.2s;
}

.shuffle-controls button:hover:not(:disabled) {
  background-color: #0056b3;
  transform: scale(1.05);
}

.shuffle-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}