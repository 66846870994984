.header {
  position: relative;
  width: 100%;
  height: 50vh;
  background: transparent; /* Sfondo nero per emulare lo stile PlayStation */
  overflow: hidden;
}

.header-content {
  position: relative;
  z-index: 10;
  color: white;
  text-align: center;
  padding-top: 6vh; /* Centra verticalmente il contenuto */
}

svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Mantieni le linee dietro il testo */
}

.title-3d {
  font-family: "Bebas Neue", Arial, Helvetica, sans-serif;
  font-size: 4em; /* Reso più grande per "Bars Maker" */
  animation: colorShift 6s ease infinite;
  display: inline-block;
  perspective: 500px;
}

.title-3d span {
  display: inline-block;
  font-family: Audiowide, "Bebas Neue", "Oswald", sans-serif;
  font-weight: bold;
  font-size: 4.5rem; /* Dimensione aggiornata per coerenza */
  animation: colorShift 6s ease infinite;
  text-shadow: 3px 2px -1px rgba(255, 255, 255, 0.5);
}

.title-3d .version {
  font-size: 1em; /* Dimensione ridotta per "0.4" */
  vertical-align: top;
  margin-left: 0.2em;
}

.version {
  animation: bounceIn 2s ease-in-out infinite;
}

/* Animazioni */
@keyframes bounceIn {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes colorShift {
  0% {
    color: #ee7752;
  }
  25% {
    color: #e73c7e;
  }
  50% {
    color: #23a6d5;
  }
  75% {
    color: #23d5ab;
  }
  100% {
    color: #ee7752;
  }
}

@keyframes drawLine {
  from {
    stroke-dashoffset: 100%;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes eraseLine {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 100%;
  }
}

.line-animation {
  stroke-dasharray: 100%;
  animation: drawLine 10s infinite linear, eraseLine 10s 10s infinite linear;
}

/* Nuove regole per linee geometriche */
@keyframes lineMove {
  0% {
    transform: translateX(0%) translateY(0%);
  }
  25% {
    transform: translateX(10%) translateY(-20%);
  }
  50% {
    transform: translateX(-20%) translateY(30%);
  }
  75% {
    transform: translateX(20%) translateY(-10%);
  }
  100% {
    transform: translateX(0%) translateY(0%);
  }
}

.geometric-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: lineMove 20s infinite ease-in-out;
}

/* Base per le linee astratte */
.geometric-line {
  position: absolute;
  stroke: rgba(255, 255, 255, 0.5);
  stroke-width: 2;
}

@keyframes randomMove1 {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(100px, 100px);
  }
}

@keyframes randomMove2 {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-100px, 100px);
  }
}

@keyframes randomMove3 {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(100px, -100px);
  }
}

@keyframes randomMove4 {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-100px, -100px);
  }
}

/* Regole base (desktop) */
.header {
  position: relative;
  width: 100%;
  height: 30vh;
  background: transparent;
  overflow: hidden;
}

.title-3d {
  font-family: "Bebas Neue", Arial, Helvetica, sans-serif;
  font-size: 4em; /* Grande per desktop */
}

/* Tablet (schermi fino a 768px) */
@media (max-width: 768px) {
  .title-3d {
    font-size: 1.5em; /* Più piccolo per tablet */
  }

  .title-3d span {
    font-size: 2rem; /* Aggiusta la dimensione dei caratteri interni se necessario */
  }
}

/* Smartphone (schermi fino a 480px) */
@media (max-width: 480px) {
  .header {
    height: 16vh; /* Altezza ridotta per garantire più spazio allo schermo */
  }

  .title-3d {
    font-size: 1.5em; /* Ancora più piccolo per smartphone */
    margin-top: -50%;
  }

  .title-3d span {
    font-size: 2rem; /* Continua ad adattare le dimensioni del font */
  }

  .version {
    font-size: 0.8em; /* Riduci anche la versione */
  }
}
