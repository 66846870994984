.email-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.email-modal-header {
  margin-bottom: 20px;
  text-align: center;
}

.email-modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.email-modal-form {
  display: flex;
  flex-direction: column;
}

.email-modal-form label {
  margin-bottom: 5px;
}

.email-modal-form input[type="text"],
.email-modal-form textarea {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.email-modal-form button[type="submit"] {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.email-modal-form button[type="submit"]:hover {
  background-color: #0056b3;
}

footer {
  margin-top: 120%;
}
