@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

#youtubePlayer {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

body {
  height: 100vh;
  margin: 0;
  padding: 0;
  background: linear-gradient(-45deg, #111111, #3f3e3e, #000000, #0e0d0d, #cccccc, #ffffff);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
}

.sdcontainer {
  width: 100%;
  border-radius: 15px;
}

.sdcontstyler {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  padding: 1%;
  border-radius: 15px;
  margin-right: 20%;
  margin-left: 20%;
}

/* nav bar */

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
  margin-top: 5px;
  margin-right: 15%;
  margin-left: 15%;
  border-radius: 15px;
}

.menu ul {
  list-style: none;
  align-items: center;
  margin-top: 20px;
  padding: 0;
  display: flex;
}

.menu li {
  margin-right: 20px;
}

.menu a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  transition: color 0.3s;
}

.menu a:hover {
  color: purple;
  opacity: 0.7;
}

/* Intestazione */
.header {
  background-color: none;
  color: #ffffff;
  text-align: center;
  padding: 20px 0;
}

.header h1 {
  font-size: 36px;
  margin: 0;
}

.header p {
  font-size: 18px;
  margin: 10px 0;
}

@keyframes animateGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.container {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: solid black 2px;
}

.youtube-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-top: 0.1%;
  margin-bottom: 2%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 0.8%;
  border-radius: 10px;
  border: solid black 2px;
}

.audio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-top: 5%;
  margin-bottom: 0.2%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 0.8%;
  border-radius: 10px;
  border: solid black 2px;
}

p {
  text-align: center;
  color: black;
  margin-bottom: 20px;
}
h1 {
  font-size: 1rem;
  font-family: Audiowide, "Bebas Neue", "Oswald", sans-serif;
  text-align: center;
  margin-bottom: 20px;
  /* Usa un colore per l'ombra che non sia completamente nero */
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.02), 1px -1px 0 rgba(0, 0, 0, 0.02),
    -1px 1px 0 rgba(0, 0, 0, 0.02), 1px 1px 0 rgba(0, 0, 0, 0.02);
  /* Colore del testo: bianco per contrastare con l'ombra leggera */
  color: rgb(255, 166, 0);
}

h2 {
  text-align: center;
  color: orange;
  margin-bottom: 20px;
  font-size: 35px;
  /* Aggiungi ombre al testo per simulare bordi */
  color: #fff; /* Colore del testo */
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; /* Simula un bordo nero intorno al testo */
}

h3 {
  text-align: center;
  color: orange;
  margin-bottom: 20px;
  font-size: 35px;
  /* Aggiungi ombre al testo per simulare bordi */
  color: #fff; /* Colore del testo */
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; /* Simula un bordo nero intorno al testo */
}

h4 {
  text-align: center;
  color: orange;
  margin-bottom: 20px;
  font-size: 35px;
  /* Aggiungi ombre al testo per simulare bordi */
  color: #fff; /* Colore del testo */
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; /* Simula un bordo nero intorno al testo */
}

h5 {
  text-align: center;
  color: orange;
  margin-bottom: 20px;
  font-size: 35px;
  /* Aggiungi ombre al testo per simulare bordi */
  color: #fff; /* Colore del testo */
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; /* Simula un bordo nero intorno al testo */
}

.input-container {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.input-container input[type="text"] {
  flex: 1;
  white-space: pre-wrap;
}

input[type="text"] {
  flex-grow: 1;
  padding: 18px;
  border-radius: 5px 0 0 5px;
  border: none;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  height: auto;
  resize: vertical;
}

/* Resto del CSS... */

.export-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20%;
  border-radius: 5px;
  padding: 10px;
  background-color: none;
  margin-right: 45%;
  margin-left: 42%;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
}

li:hover {
  background-color: #f2f2f2;
}

li span {
  flex-grow: 1;
  margin-right: 10px;
}

li button {
  background-color: transparent;
  border: none;
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease;
  border-radius: 10px;
  padding: 10px 10px;
}

li button:hover {
  color: #333;
}

footer {
  background-color: #f7f7f7;
  padding: 20px 0;
  text-align: center;
  font-size: 14px;
  color: #333;
  border-top: 1px solid #ddd;
}

.footer-content {
  max-width: 960px;
  margin: 0 auto;
}

.footer-content p {
  margin: 10px 0;
}

.footer-content a {
  color: #007bff;
  text-decoration: none;
}

.footer-content a:hover {
  text-decoration: underline;
}

.privacycontents {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

/* Media query per adattare la pagina ai dispositivi mobili */
@media (max-width: 600px) {
  /* Aumenta la leggibilità riducendo margini e padding */
  .sdcontstyler,
  .youtube-container,
  .audio-container,
  .container,
  .export-container {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
  }

  /* Adatta il menu di navigazione per dispositivi mobili */
  .menu {
    justify-content: space-around;
    padding: 10px 0;
  }

  .menu ul {
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
    width: 100%;
  }

  .menu li {
    margin-right: 0;
    margin-bottom: 0;
  }

  .menu a {
    font-size: 14px; /* Riduci leggermente la dimensione del font se necessario */
  }

  /* Adatta l'intestazione per dispositivi mobili */
  .header {
    padding: 20px 10px;
  }

  @keyframes textGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .header h1 {
    font-size: 2.5rem;
    background: linear-gradient(-45deg, white, gold, silver, orange);
    background-size: 400% 400%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; /* Rende il testo trasparente, mostrando solo il gradiente */
    -webkit-text-fill-color: transparent; /* Necessario per alcuni browser */
    animation: textGradient 5s ease infinite;
    font-size: 18px;
  }

  .header p,
  h2,
  h3,
  h4,
  h5 {
    font-size: 20px; /* Adatta la dimensione del testo per gli elementi dell'intestazione e i titoli */
  }

  /* Assicurati che input e pulsanti siano facilmente interagibili */
  .input-container input[type="text"],
  button {
    font-size: 10px; /* Mantieni la leggibilità del testo nei campi di input e pulsanti */
  }

  /* Adatta i pulsanti per essere più facilmente cliccabili */
  button {
    padding: 12px 24px;
    font-size: 16px; /* Adatta la dimensione del testo per i pulsanti */
  }

  /* Assicurati che il footer sia leggibile e ben spaziato */
  footer {
    font-size: 12px;
    padding: 10px 5px; /* Riduci il padding per il footer */
  }

  .footer-content p,
  .footer-content a {
    font-size: 12px; /* Riduci la dimensione del testo per il contenuto del footer */
  }

  /* Riduci la dimensione degli elementi della lista e dei pulsanti */
  ul#taskList li,
  .export-container button,
  li button {
    font-size: 14px; /* Adatta la dimensione del testo per gli elementi della lista e i pulsanti */
  }

}
