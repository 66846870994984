li {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 8px;
}

.PrivacyPolicy {
  background-color: transparent; /* or 'none', depending on what you need */
}
