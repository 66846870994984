.post-list {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.post-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
}

.post-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.post-avatar {
  width: 40px;
  height: 40px;
}

.post-user {
  font-weight: bold;
}

.post-date {
  color: #888;
}

.legend {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.legend-item {
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
}

.bars-container {
  margin-bottom: 20px;
}

.bar-container {
  padding: 10px;
  margin: 5px 0;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.bar-text {
  color: black;
}

.image-container img {
  max-width: 100%;
  border-radius: 8px;
}

.video-container, .audio-container {
  margin-top: 20px;
}

.video-controls, .audio-controls {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.post-actions {
  margin-top: 20px;
}

/* Stile aggiornato per i pulsanti delle reazioni */
.post-actions .reaction-button {
  display: flex;
  flex-direction: column; /* Allinea icona e numero in verticale */
  align-items: center; /* Centra orizzontalmente */
  justify-content: center; /* Centra verticalmente */
  gap: 8px; /* Spazio tra icona e numero */
  padding: 8px 8px; /* Padding per migliorare il cliccabile */
  border-radius: 8px;
  background-color: #ffffff; /* Sfondo per distinguere i pulsanti */
  transition: background-color 0.3s ease;
  border: none; /* Rimuove il bordo predefinito */
  cursor: pointer; /* Cambia il cursore al passaggio del mouse */
}

.post-actions .reaction-button:hover {
  background-color: #ffffff; /* Effetto hover */
}

.post-actions .reaction-icon {
  font-size: 24px; /* Dimensione dell'icona */
  margin-bottom: 4px; /* Aggiunge spazio sotto l'icona */
}

.post-actions .reaction-count {
  font-size: 0.875rem !important; /* Forza la dimensione del testo */
  font-weight: 400 !important; /* Forza il peso del font */
  color: #333 !important; /* Forza il colore del testo */
  text-align: center; /* Allinea il numero al centro */
  margin: 0.6rem !important; /* Forza il margine */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; /* Forza il font family */
  line-height: 1.43 !important; /* Forza l'altezza della linea */
  letter-spacing: 0.01071em !important; /* Forza la spaziatura tra le lettere */
}

.post-comments {
  margin-top: 20px;
}

.comment {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.comment-avatar {
  width: 30px;
  height: 30px;
}

.comment-content {
  flex-grow: 1;
}

.comment-input {
  margin-top: 20px;
}

.filters-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filters-container .MuiSelect-select {
  background-color: white;
  border-radius: 8px;
  padding: 8px 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #333;
}

.filters-container .MuiSelect-icon {
  color: #333;
}

.filters-container .MuiOutlinedInput-notchedOutline {
  border: none;
}

.bars-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.bar-container {
  display: inline-flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  flex: 1 1 auto;
  min-width: 100px;
  max-width: 100%;
}