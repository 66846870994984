.buy-me-a-coffee-btn {
    margin: 2%;
    background: #ff813f; /* Initial background color */
    color: white;
    padding: 40px 45%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0 0 10px #ff813f; /* Glow effect */
    text-transform: uppercase;
    animation: glowing 1.5s infinite alternate; /* Animation for the glow */
  }
  
  .buy-me-a-coffee-btn:hover {
    background-color: #e06c1f;
  }
  
  /* Keyframes for the glowing effect */
  @keyframes glowing {
    from {
      box-shadow: 0 0 5px #ff813f, 0 0 10px #ff813f, 0 0 15px #ff813f,
        0 0 20px #e06c1f;
      transform: scale(1);
    }
    to {
      box-shadow: 0 0 20px #ff813f, 0 0 30px #ff813f, 0 0 40px #ff813f,
        0 0 50px #e06c1f;
      transform: scale(1.05);
    }
  }
  

@media (max-width: 768px) {
.buy-me-a-coffee-btn {
  background: #ff813f; /* Initial background color */
  color: white;
  padding: 10px 38%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 0 10px #ff813f; /* Glow effect */
  text-transform: uppercase;
  animation: glowing 1.5s infinite alternate; /* Animation for the glow */
}

.buy-me-a-coffee-btn:hover {
  background-color: #e06c1f;
}

/* Keyframes for the glowing effect */
@keyframes glowing {
  from {
    box-shadow: 0 0 5px #ff813f, 0 0 10px #ff813f, 0 0 15px #ff813f,
      0 0 20px #e06c1f;
    transform: scale(1);
  }
  to {
    box-shadow: 0 0 20px #ff813f, 0 0 30px #ff813f, 0 0 40px #ff813f,
      0 0 50px #e06c1f;
    transform: scale(1.05);
  }
}
}