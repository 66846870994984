@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.audio-uploader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(-45deg, #000000, #191919, #000000, #000000, #000000, #000000);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
  border-radius: 18px;
  padding: 24;
  max-width: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.audio-player::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><path fill='rgba(255, 255, 255, 0.6)' d='M22.503 49.607l4.44-4.44 9.885 9.886-4.44 4.44-9.885-9.886zm10.41 1.033l4.44-4.44 9.886 9.886-4.44 4.44-9.886-9.886zm17.926 5.683l4.44-4.44 9.886 9.886-4.44 4.44-9.886-9.886zm-17.925-17.925l4.44-4.44 9.886 9.886-4.44 4.44-9.886-9.886zm10.41 1.033l4.44-4.44 9.886 9.886-4.44 4.44-9.886-9.886zm17.926 5.683l4.44-4.44 9.886 9.886-4.44 4.44-9.886-9.886zm-17.925-17.926l4.44-4.44 9.886 9.886-4.44 4.44-9.886-9.886zm10.41 1.033l4.44-4.44 9.886 9.886-4.44 4.44-9.886-9.886z'/></svg>");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  animation: moveParticles 30s linear infinite;
  opacity: 0.3;
  pointer-events: none;
}

.audio-input {
  margin-bottom: 20px;
  cursor: pointer;
}

.audio-player {
  max-width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.audio-uploader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Distanza tra gli elementi */
}

.url-input,
.file-input {
  width: 90%;
  padding: 8px;
  border: 2px solid #23a6d5; /* Colore bordo */
  border-radius: 5px; /* Angoli arrotondati */
}

.react-player {
  margin-top: 20px;
  width: 100%; /* Larghezza al 100% del contenitore */
  min-height: 200px; /* Altezza minima per garantire che il contenuto sia visibile */
  /* Mantieni le proporzioni del video di YouTube, solitamente 16:9 */
  aspect-ratio: 16 / 9;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ffffff; /* Colore di sfondo del bottone */
  color: rgb(0, 0, 0); /* Colore del testo */
  border: 1px solid transparent; /* Rimuove il bordo */
  transition: background-color 0.3s ease; /* Transizione per l'hover */
  margin-bottom: 5%;
}

.custom-file-upload:hover {
  background-color: #000000; /* Colore di sfondo al passaggio del mouse */
  color: white;
}
