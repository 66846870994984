.dashboard-header {
  position: relative;
  width: 100%;
  height: 30vh;
  background: transparent;
  overflow: hidden;
}

.header-content {
  position: relative;
  z-index: 10;
  color: white;
  text-align: center;
  padding-top: 2vh;
}

svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.title-3d {
  font-family: 'Bebas Neue', Arial, Helvetica, sans-serif;
  font-size: 2em;
  animation: colorShift 6s ease infinite;
  display: inline-block;
  perspective: 5px;
  padding: 30px;
}

.title-3d span {
  display: inline-block;
  font-family: Audiowide, 'Bebas Neue', 'Oswald', sans-serif;
  font-weight: bold;
  font-size: 2rem;
  animation: colorShift 6s ease infinite;
  text-shadow: 3px 2px -1px rgba(255, 255, 255, 0.5);
}

@keyframes colorShift {
  0% {
    color: #ee7752;
  }
  25% {
    color: #e73c7e;
  }
  50% {
    color: #23a6d5;
  }
  75% {
    color: #23d5ab;
  }
  100% {
    color: #ee7752;
  }
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.dashboard-title {
  font-size: 2.5rem;
  color: #ff6600;
  margin-bottom: 5px;
}

.post-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input-text {
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  resize: none;
}

.bars-preview {
  width: 90%;
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
}

.preview-bar {
  color: black;
  margin: 5px;
  padding: 5px 10px;
  border: 1px solid #000;
  border-radius: 5px;
  display: inline-block;
  max-width: calc(100% - 20px);
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.creative-object-3d {
  margin: 0 auto;
  margin-top: -2%;
  width: 20%;
  height: 30px; /* Altezza adeguata per visualizzare il prisma */
}

.glowing-rectangles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.glowing-rectangles rect {
  fill: orange;
}

.color-option {
  display: inline-block;
  padding: 10px;
  margin: 5px;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
}

.color-option.anger {
  background-color: #ff0000;
}
.color-option.security {
  background-color: #ffd700;
}
.color-option.sadness {
  background-color: #0000ff;
}
.color-option.questioning {
  background-color: #00ff00;
}
.color-option.hate {
  background-color: #8b0000;
}
.color-option.calm {
  background-color: #00ced1;
}
.color-option.double {
  background-color: #800080;
}

.legend-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
}

.creative-object-3d {
  margin: auto;
  width: 50%;
  height: 50%; /* Altezza aumentata per il prisma */
  margin-top: -20%;
}
