/* Stile generale del container */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Titolo principale */
.css-1vw6mcs-MuiTypography-root, h {
  text-align: center;
  color: #ff6f00; /* Colore arancione nitido */
  font-size: 2.2rem;
  margin-bottom: 20px;
  font-weight: 600;
  font-family: Audiowide, "Bebas Neue", "Oswald", sans-serif;
}

/* Selettore della lingua */
.language-selector {
  margin-bottom: 20px;
  text-align: center;
}

.language-selector label {
  font-size: 0.9rem;
  color: #555;
  margin-right: 10px;
}

.language-selector select {
  padding: 6px 10px;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.language-selector select:hover {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

/* Contenitore dell'input */
.input-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
}

.input-container input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 0.9rem;
  background-color: #fff;
  color: #333;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.input-container input:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  outline: none;
}

.input-container button {
  padding: 8px 12px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.3s, transform 0.2s;
}

.input-container button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Media Query per dispositivi mobili */
@media (max-width: 768px) {
  .input-container {
    flex-direction: column;
    align-items: flex-end; /* Allinea tutto a destra */
  }

  .input-container input {
    width: 100%; /* Occupa tutta la larghezza */
    max-width: calc(100% - 20px); /* Lascia uno spazio di margine */
    margin-bottom: 10px; /* Spazio tra la barra e i bottoni */
  }

  .input-container button {
    width: auto; /* Dimensione naturale */
    margin-left: auto; /* Allinea a destra */
  }

  /* I bottoni sono disposti verticalmente sulla destra */
  .input-container button + button {
    margin-top: 5px; /* Spazio tra i bottoni */
  }
}

/* Lista delle barre */
.ulli {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.bar-container {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.bar-container:hover {
  background-color: #f0f0f0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.bar-container.editing {
  background-color: #e3f2fd;
}

/* Input di editing */
.editing-input {
  width: 100%;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 0.9rem;
  background-color: #fff;
  color: #333;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.editing-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  outline: none;
}

/* Pulsanti di salvataggio e cancellazione */
.save-bars-button-container {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.save-bars-button-container button {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background-color: #ffa113;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s, transform 0.2s;
}

.save-bars-button-container button:hover {
  background-color: #ffcf6f;
  transform: scale(1.05);
}

.clear-bars-button {
  background-color: #dc3545;
}

.clear-bars-button:hover {
  background-color: #c82333;
}

/* Sezione "Your Bars" */
.your-bars-section {
  margin-top: 20px;
  text-align: center;
}

.dropdown-toggle {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background-color: #17a2b8;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s, transform 0.2s;
}

.dropdown-toggle:hover {
  background-color: #138496;
  transform: scale(1.05);
}

/* Griglia per i salvataggi */
.saves-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 12px;
  margin-top: 12px;
}

.save-card {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.save-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.save-card h4 {
  margin: 0;
  font-size: 1rem;
  color: #ff6f00;
  font-weight: 600;
}

.save-card p {
  margin: 6px 0;
  font-size: 0.8rem;
  color: #666;
}

.save-card .actions {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.save-card .actions button {
  padding: 5px 10px;
  border: none;
  border-radius: 6px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.3s, transform 0.2s;
}

.save-card .actions button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.save-card .actions .export-btn {
  background-color: #ffc107;
  color: black;
}

.save-card .actions .export-btn:hover {
  background-color: #e0a800;
}

.save-card .actions .delete-btn {
  background-color: #dc3545;
}

.save-card .actions .delete-btn:hover {
  background-color: #c82333;
}

.import-button {
  padding: 9px 0px;
  border: solid black;
  border-radius: 8px;
  background-color: #ff6f00;
  color: white;
  cursor: pointer;
  font-size: 0.4rem;
  transition: background-color 0.3s, transform 0.2s;
}

.import-button:hover {
  background-color: #e65c00;
  transform: scale(1.05);
}

/* Temi base */
body.light-mode {
  --background-color: #ffffff;
  --text-color: #000000;
}

body.dark-mode {
  --background-color: #1a1a1a;
  --text-color: #ffffff;
}

.container {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
}

/* Stile per il metronomo */
.metronome {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.metronome h3 {
  margin-bottom: 16px;
  font-size: 1.4rem;
  color: #ff6f00;
  font-weight: 600;
}

.metronome label {
  font-size: 0.9rem;
  color: #555;
  margin-right: 10px;
}

.metronome input[type="number"] {
  padding: 6px 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 0.9rem;
  background-color: #fff;
  color: #333;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.metronome input[type="number"]:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  outline: none;
}

.metronome select {
  padding: 6px 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 0.9rem;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.metronome select:hover {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

.metronome button {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background-color: #ffb625;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s, transform 0.2s;
}

.metronome button:hover {
  background-color: #ffa74a;
  transform: scale(1.05);
}

/* Stile per l'input su dispositivi mobili */
.input-container input {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.input-container input:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  outline: none;
}

.css-1vw6mcs-MuiTypography-root {
  font-family: Audiowide, "Bebas Neue", "Oswald", sans-serif !important;
  font-size: 15px !important;
}

/* Stili per i temi selezionabili */
.theme-selector {
  margin-top: 20px;
  text-align: center;
}

.theme-selector label {
  font-size: 0.9rem;
  color: #555;
  margin-right: 10px;
}

.theme-selector select {
  padding: 6px 10px;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.theme-selector select:hover {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

/* Tema Light Mode (predefinito) */
.theme-light {
  --background-color: #ffffff;
  --text-color: #000000;
}

/* Tema Dark Mode */
.theme-dark {
  --background-color: #1a1a1a;
  --text-color: #ffffff;
}

/* Tema Modern Gradient 1 */
.theme-modern1 {
  --background-color: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  --text-color: #ffffff;
}

/* Tema Modern Gradient 2 */
.theme-modern2 {
  --background-color: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
  --text-color: #ffffff;
}

/* Tema Modern Gradient 3 */
.theme-modern3 {
  --background-color: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  --text-color: #ffffff;
}

/* Tema Cyber Mode */
.theme-cyber {
  --background-color: #0f0f0f;
  --text-color: #00ff00;
}
