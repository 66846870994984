/* Contenitore Principale */
.post-form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Anteprima delle Barre */
.bars-preview {
  margin-top: 20px;
}

.preview-bar {
  padding: 10px;
  margin: 5px 0;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Selettore di Colore Emotivo */
.emotional-colors {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.color-option {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  transition: transform 0.2s ease;
}

.color-option:hover {
  transform: scale(1.2);
}

/* Anteprima del File */
.file-preview {
  margin-top: 20px;
}

.preview-image,
.preview-video,
.preview-audio {
  max-width: 100%;
  border-radius: 8px;
}

/* Pulsanti */
.buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.buttons-container button {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.buttons-container button:hover {
  background-color: #e0e0e0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .post-form-container {
    padding: 15px;
  }

  .color-option {
    width: 25px;
    height: 25px;
  }

  .preview-bar {
    font-size: 12px;
    padding: 8px;
  }

  .buttons-container button {
    padding: 8px 15px;
    font-size: 12px;
  }
}